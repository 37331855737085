import BaseControl from './base-control'

import { KEYCODES } from 'core/helpers/global/enums'

import Layout from './templates/site-control.tpl'

import './style/site-control.scss'

class SiteControl extends BaseControl {
  constructor (controller) {
    super(controller, () => Layout, controller.model.pages, 'site')
    this.lazyImageSelector = ''
  }

  bindLayout (element, data) {
    super.bindLayout(element, data)

    // ADA Anchoring.
    const firstContentElement = element.querySelector('[data-property="global.callToAction"]') || element.querySelector('.container.content')
    firstContentElement?.setAttribute('id', 'ada-tab-anchor')

    element.querySelector('a[data-tag="ada-skip-link"]').innerText = this.controller.model.globalBinding.translations.ada.skipToMainText

    // Let the document know when the mouse is being used
    element.addEventListener('mousedown', function () {
      element.classList.add('using-mouse')
    })

    // Re-enable focus styling when Tab is pressed
    element.addEventListener('keydown', function (event) {
      if (event.keyCode === KEYCODES.TAB) {
        element.classList.remove('using-mouse')
      }
    })
  }
}
export default SiteControl
