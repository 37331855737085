import GlobalProperties from 'core/helpers/global/global-properties'

class CollectPublish {
  constructor (appmarketApps, collectSiteMaps) {
    this.appmarketApps = appmarketApps
    this.scripts = ''
    this.styles = ''
    this.featureSettings = {}
    this.publishedFeatures = []
    this.developerScripts = []
    this.collectSiteMaps = collectSiteMaps
  }

  static wrapFeatureScript (featureName, script) {
    // wrap in a function otherwise static variabeles outside class will bleed into other features
    return LAYOUT_MAKER ? '' : `${script};`
  }

  getScriptUrls () {
    return this.developerScripts
  }

  getScript () {
    let script = `${this.scripts} window._featureSettings=${JSON.stringify(this.featureSettings)};`
    if (LAYOUT_MAKER) {
      // for the layout maker we load to many scripts, use this hack instead
      script += `window.__publishedFeatures=${JSON.stringify(this.publishedFeatures)};`
    }
    return script
  }

  publishScriptUrl (featureDefinition) {
    if (featureDefinition.scriptUrl && this.developerScripts.indexOf(featureDefinition.scriptUrl) < 0) {
      this.developerScripts.push(featureDefinition.scriptUrl)
    }
  }

  publish (featureName, featureDefinition, sectionController) {
    const scriptUrl = featureDefinition.url && featureDefinition.timestamp && featureDefinition.url.replace('{timestamp}', featureDefinition.timestamp)
    if (scriptUrl) {
      if (this.developerScripts.indexOf(scriptUrl) < 0) {
        this.developerScripts.push(scriptUrl)
      }
    } else if (featureDefinition.scriptUrl) {
      this.publishScriptUrl(featureDefinition)
    } else {
      this.scripts += CollectPublish.wrapFeatureScript(featureName, featureDefinition.scripts)
    }

    this.publishedFeatures.push(featureName)
    if (featureDefinition.styles) {
      this.styles += featureDefinition.styles
    }
    const featureSettings = featureDefinition.settings
    if (featureSettings) {
      // mimic settings object like how it is defined in layouts
      const settings = {}
      ;['dependingGlobalFeatures', 'isGlobal'].forEach(propName => {
        settings[propName] = featureSettings[propName]
      })

      this.featureSettings[featureName] = { settings }

      // export translations in correct language
      if (featureSettings.translationKeys) {
        this.featureSettings.translations = this.featureSettings.translations || {}
        featureSettings.translationKeys.forEach(key => {
          this.featureSettings.translations[key] = GlobalProperties.localize(key)
        })
      }

      // site map integration
      if (this.appmarketApps && featureSettings.appmarketApp && !this.collectSiteMaps[featureSettings.appmarketApp]) {
        const app = this.appmarketApps.find(i => i.sku === featureSettings.appmarketApp)
        if (app) {
          const isNode = typeof global === 'object'
          const replaceWindow = isNode ? 'global.' : 'window.'

          const getPageUri = pageType => {
            const site = sectionController.getSiteController().model
            const page = site.pages.find(i => i.pageType === pageType)
            if (page) {
              return page.uriPath
            }
          }

          if (isNode) {
            global.getPageUri = getPageUri
          } else {
            window.getPageUri = getPageUri
          }

          // eslint-disable-next-line
          const result = eval(
            featureDefinition.scripts.replace(/window./gi, replaceWindow) +
              `; var _class = ${replaceWindow}__features["${featureName}"]; _class.getSiteMapUrl && _class.getSiteMapUrl(${JSON.stringify(
                app
              )}, ${replaceWindow}getPageUri)`
          )
          if (result) {
            this.collectSiteMaps[featureSettings.appmarketApp] = result
          }
        }
      }
    }
  }
}
export default CollectPublish
